import { captureException } from "@sentry/react";
import { useRouteError } from "react-router-dom";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import NavBar from "./components/ui/nav_bar";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  captureException(error);

  return (
    <SessionAuth>
      <div className="relative z-10">
        <NavBar home>
          <div />
        </NavBar>
      </div>
      <div className="fixed top-0 left-0 h-screen w-full flex flex-col items-center justify-center text-center font-main">
        <h1 className="text-xl font-semibold">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </SessionAuth>
  );
}
