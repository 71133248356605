import { ActionFunctionArgs, redirect } from "react-router-dom";
import { createTally, getTally, updateTally } from "./tally";
import { GameStore } from "@/storage";
import { GameType } from "./game";

export async function getTracker(args: ActionFunctionArgs): Promise<any> {
  let gameID = args.params.gameID || "";
  let type = GameStore.get(gameID)?.type;

  let data;
  switch (type) {
    case GameType.Tally:
      data = await getTally(args);
  }

  if (data) return data;
  throw Error(`Error loading ${type}.`);
}

export async function createTracker(
  args: ActionFunctionArgs,
): Promise<Response> {
  let gameID = args.params.gameID || "";
  let groupID = args.params.groupID || "";
  let type = GameStore.get(gameID)?.type;

  let response;
  switch (type) {
    case GameType.Tally:
      response = await createTally(args);
  }

  if (response && response.ok) {
    let id = (await response.json()).id;
    return redirect(`/games/${gameID}/groups/${groupID}/trackers/${id}`);
  }
  throw Error(`Error creating ${type}.`);
}

export async function updateTracker(
  args: ActionFunctionArgs,
): Promise<Response> {
  let gameID = args.params.gameID || "";
  let groupID = args.params.groupID || "";
  let type = GameStore.get(gameID)?.type;

  let response;
  switch (type) {
    case GameType.Tally:
      response = await updateTally(args);
  }

  if (response && response.ok) {
    return redirect(`/games/${gameID}/groups/${groupID}/trackers`);
  }
  throw Error(`Error updating ${type}.`);
}
