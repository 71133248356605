import { UserRes, getDefaultAvatars } from "@/api/user";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { DoubleQ } from "@/components/ui/double_letters";
import FormError from "@/components/ui/form_error";
import { Input } from "@/components/ui/input";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSubmit } from "react-router-dom";

function makeFormData(data: ProfileFormInputs): FormData {
  let ret = new FormData();
  ret.append("name", data.name);
  const image_file = data.image_files?.[0];

  ret.append("upload", data.upload ? "true" : "false");
  if (image_file) {
    ret.append("image_file", image_file);
  }
  if (data.image_path) {
    ret.append("image_path", data.image_path);
  }

  return ret;
}

export type ProfileFormInputs = {
  name: string;
  upload: boolean;
  image_files: FileList | undefined;
  image_path: string;
};

type profileFormProps = {
  action: string;
  callback?: () => void;
  btnTxt: string;
  initialData?: UserRes;
};
export function ProfileForm(props: profileFormProps) {
  const navSubmit = useSubmit();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormInputs>({
    defaultValues: {
      name: props.initialData?.name || "",
      upload: false,
      image_path: props.initialData?.avatarUrl || "",
    },
  });

  const [defaultAvatars, defaultAvatarsSetter] = useState<string[]>([]);
  useEffect(() => {
    async function get() {
      const avatars = await getDefaultAvatars();
      defaultAvatarsSetter(avatars);
    }
    get();
  }, []);

  const [showImage, showImageSetter] = useState<string>(
    props.initialData?.avatarUrl || "",
  );

  function fileInputChange(file: File | undefined) {
    if (file) {
      setValue("upload", true);
      showImageSetter(URL.createObjectURL(file));
      setValue("image_path", "");
    }
  }
  function avatarSelect(url: string) {
    setValue("upload", false);
    showImageSetter(url);
    setValue("image_files", undefined);
  }

  function submit(data: ProfileFormInputs) {
    let formData = makeFormData(data);

    navSubmit(formData, {
      method: "post",
      action: props.action,
      encType: "multipart/form-data",
    });
    if (props.callback) {
      props.callback();
    }
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col gap-6 items-center"
    >
      <div className="flex flex-col items-center">
        <div className="relative inline-block">
          <Avatar size="lg">
            <AvatarImage src={showImage} />
            <AvatarFallback>
              <DoubleQ size="lg" />
            </AvatarFallback>
          </Avatar>
          <Button
            variant="icon"
            size="icon"
            className="absolute bottom-2 right-2"
            asChild
          >
            <label>
              <FontAwesomeIcon icon={faCamera} />
              <Input
                type="file"
                accept="image/*"
                className="hidden"
                {...register("image_files", {
                  onChange: (e) => fileInputChange(e.currentTarget?.files[0]),
                  deps: "image_path",
                  validate: (value) => {
                    if (value) {
                      const test = (value.item(0)?.size || 0) < 5 * 1024 * 1024;
                      return test || "Maximum image size 5MB";
                    } else {
                      return true;
                    }
                  },
                })}
              />
            </label>
          </Button>
        </div>
        <FormError error={errors.image_files} />
      </div>
      <div className="flex flex-col items-center">
        <Input
          placeholder="Enter a name..."
          {...register("name", { required: "This field is required." })}
        />
        <FormError error={errors.name} />
      </div>
      <Card>
        <CardContent className="p-4">
          <div className="grid auto-rows-fr grid-cols-3 gap-2">
            {defaultAvatars.map((src, i) => (
              <label key={i}>
                <input
                  type="radio"
                  className="hidden peer"
                  {...register("image_path", {
                    onChange: (e) => avatarSelect(e.currentTarget.value),
                    validate: (value, formValues) => {
                      const test =
                        Boolean(value) ||
                        Boolean(formValues.image_files?.length);
                      return test || "An image is required.";
                    },
                  })}
                  value={src}
                />
                <Avatar className="peer-checked:border-0 peer-checked:ring-4 peer-checked:ring-orangeLight">
                  <AvatarImage src={src} />
                </Avatar>
              </label>
            ))}
          </div>
          <FormError error={errors.image_path} />
          <Input type="checkbox" className="hidden" {...register("upload")} />
        </CardContent>
      </Card>
      <Button type="submit">{props.btnTxt}</Button>
    </form>
  );
}
