import {
  UserRes,
  addFriend,
  fulfilFriend,
  getFriendRequests,
  getFriends,
} from "@/api/user";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import FormError from "@/components/ui/form_error";
import { Input } from "@/components/ui/input";
import NavBar from "@/components/ui/nav_bar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  faCircleInfo,
  faCopy,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLoaderData, useRevalidator } from "react-router-dom";

function InviteDialog(props: { f: UserRes }) {
  const [success, successSetter] = useState(false);

  type Inputs = {
    email: string;
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  async function submit(data: Inputs) {
    const error = await fulfilFriend(props.f.id, data.email);
    if (!error) {
      successSetter(true);
    } else {
      setError("root.server", { type: "custom", message: error });
    }
  }

  const domainRef = useRef<HTMLInputElement>(null);
  return (
    <DialogContent>
      {!success ? (
        <>
          <DialogHeader>
            <DialogTitle>Invite {props.f.name}</DialogTitle>
            <DialogDescription>
              Invite a friend to fulfil the selected account.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(submit)}>
            <FormError error={errors.root?.server} />
            <Input
              type="email"
              placeholder="Enter email..."
              {...register("email", {
                required: "This field is required.",
                onChange: () => clearErrors(),
              })}
            />
            <FormError error={errors.email} />
            <DialogFooter>
              <Button variant="constructive" size="sm" className="mx-0">
                Invite
              </Button>
            </DialogFooter>
          </form>
        </>
      ) : (
        <>
          <DialogHeader>
            <DialogTitle>Success</DialogTitle>
            <DialogDescription>
              They can now log into the account using their email. Please share
              the link.
            </DialogDescription>
          </DialogHeader>
          <div className="flex gap-1 items-center">
            <Input
              ref={domainRef}
              defaultValue={import.meta.env.VITE_SITE_DOMAIN}
              readOnly
            />
            <Button
              variant="iconAlt"
              size="iconAlt"
              className="shrink-0"
              onClick={() => {
                domainRef.current?.select();
                domainRef.current?.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(import.meta.env.VITE_SITE_DOMAIN);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                type="button"
                variant="outline"
                size="sm"
                className="mx-0"
              >
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </>
      )}
    </DialogContent>
  );
}

type friendsLoaderT = {
  requests: UserRes[];
  friends: UserRes[];
};
export async function friendsLoader(): Promise<friendsLoaderT> {
  const requests = await getFriendRequests();
  const friends = await getFriends();
  return { requests: requests, friends: friends };
}

export default function FriendsList() {
  const revalidator = useRevalidator();
  const loaderData = useLoaderData() as friendsLoaderT;
  const requests = loaderData.requests;
  const friends = loaderData.friends.filter((f) => f.email);
  const localFriends = loaderData.friends.filter((f) => !f.email);

  function userRow(avatar: string, name: string, email?: string) {
    return (
      <div className="flex items-center gap-2">
        <Avatar>
          <AvatarImage src={avatar} />
        </Avatar>
        <div className="flex flex-col">
          <p className="font-main font-medium leading-none">{name}</p>
          {email && <p className="font-main text-xs text-brown/60">{email}</p>}
        </div>
      </div>
    );
  }
  // TODO; refactor with the InviteDialog?
  const [addFriendSuccess, addFriendSuccessSetter] = useState(false);
  function addFriendDialog() {
    type Inputs = {
      email: string;
    };

    const {
      register,
      handleSubmit,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<Inputs>();

    async function submit(data: Inputs) {
      const error = await addFriend(data.email);
      if (!error) {
        addFriendSuccessSetter(true);
      } else {
        setError("root.server", { type: "custom", message: error });
      }
    }

    return (
      <DialogContent>
        {!addFriendSuccess ? (
          <>
            <DialogHeader>
              <DialogTitle>Add</DialogTitle>
              <DialogDescription>
                Add a friend with an account by email.
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleSubmit(submit)}>
              <FormError error={errors.root?.server} />
              <Input
                type="email"
                placeholder="Enter email..."
                {...register("email", {
                  required: "This field is required.",
                  onChange: () => clearErrors(),
                })}
              />
              <FormError error={errors.email} />
              <DialogFooter>
                <Button variant="constructive" size="sm" className="mx-0">
                  Add
                </Button>
              </DialogFooter>
            </form>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Success</DialogTitle>
              <DialogDescription>
                A friend request has been sent. The user will now see games that
                you add them to.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="constructive"
                  size="sm"
                  className="mx-0"
                >
                  Close
                </Button>
              </DialogClose>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    );
  }

  return (
    <>
      <NavBar back="/home" logo />

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl text-center font-medium">Play Together</h2>

        {requests.length != 0 && (
          <Card>
            <CardHeader>
              <CardTitle>Friend requests</CardTitle>
            </CardHeader>
            <CardContent className="mt-2">
              <div className="flex flex-col gap-1">
                {requests.map((f) => (
                  <div key={f.id} className="flex items-center justify-between">
                    {userRow(f.avatarUrl, f.name, f.email)}
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        addFriend(f.email || "").then(revalidator.revalidate);
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}

        <Card>
          <CardHeader>
            <CardTitle>Friends</CardTitle>
            <Dialog
              onOpenChange={() => {
                revalidator.revalidate();
                addFriendSuccessSetter(false);
              }}
            >
              <DialogTrigger asChild>
                <Button variant="constructive" size="xs">
                  Add Friend
                </Button>
              </DialogTrigger>
              {addFriendDialog()}
            </Dialog>
          </CardHeader>
          <CardContent className="mt-2">
            <div className="flex flex-col gap-1">
              {friends.length == 0 && (
                <p className="font-main">No one here yet!</p>
              )}
              {friends.map((f) => (
                <div key={f.id} className="flex items-center justify-between">
                  {userRow(f.avatarUrl, f.name, f.email)}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>
              Local Accounts
              <Popover>
                <PopoverTrigger>
                  <FontAwesomeIcon icon={faCircleInfo} className="ml-2" />
                </PopoverTrigger>
                <PopoverContent className="w-48 text-sm">
                  Local accounts are self-created and not owned by anyone. Only
                  invited users can access them.
                </PopoverContent>
              </Popover>
            </CardTitle>
            <Button variant="constructive" size="xs" asChild>
              <Link to="/friends/create">
                <FontAwesomeIcon icon={faPlus} className="mr-1" /> Create
              </Link>
            </Button>
          </CardHeader>
          <CardContent className="mt-2">
            <div className="flex flex-col gap-1">
              {localFriends.length == 0 && (
                <p className="font-main">No one here yet!</p>
              )}
              {localFriends.map((f) => (
                <div key={f.id} className="flex items-center justify-between">
                  {userRow(f.avatarUrl, f.name, f.email)}
                  <Dialog onOpenChange={revalidator.revalidate}>
                    <DialogTrigger asChild>
                      <Button variant="outline" size="xs">
                        Invite
                      </Button>
                    </DialogTrigger>
                    <InviteDialog f={f} />
                  </Dialog>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
