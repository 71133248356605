import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import FormError from "@/components/ui/form_error";
import NavBar from "@/components/ui/nav_bar";
import { Button } from "@/components/ui/button";
import { GameType } from "@/api/game";

export type GameStateT = {
  name: string;
  type: string;
};

type gameSelectProps = {
  nextStep: (step: string) => void;
  gameState: MutableRefObject<GameStateT>;
};
export function GameTypeComp(props: gameSelectProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GameStateT>({ defaultValues: props.gameState.current });

  // These names need to match the BE
  const gameTypes = [
    {
      name: GameType.Tally,
      icon: "/images/tally_icon.svg",
      step: "submit",
      desc: "A simple count of scores.",
    },
    // {
    //   name: GameType.Scorecard,
    //   icon: "/images/scorecard_icon.svg",
    //   step: "submit",
    //   desc: "A table of scores for games with scoresheets.",
    // },
    // {
    //   name: GameType.Tournament,
    //   icon: "/images/tournament_icon.svg",
    //   step: "submit",
    //   desc: "A battle between players.",
    // },
  ];

  function submit(data: GameStateT) {
    props.gameState.current = data;
    let type = gameTypes.find((gt) => gt.name == data.type);
    if (type) props.nextStep(type.step);
  }

  return (
    <>
      <NavBar back="/home" logo />

      <div className="flex flex-col items-center gap-6">
        <h2 className="text-xl font-medium">Start Scoring</h2>
        <form
          onSubmit={handleSubmit(submit)}
          className="flex flex-col gap-6 items-center w-full"
        >
          <div className="flex flex-col items-center">
            <Input
              placeholder="Enter game name..."
              {...register("name", { required: "This field is required." })}
            />
            <FormError error={errors.name} />
          </div>
          <p>Pick a score type...</p>
          <div className="flex flex-wrap gap-6 justify-around w-full">
            {gameTypes.map((t) => (
              <label key={t.name} className="flex">
                <input
                  type="radio"
                  className="hidden peer"
                  {...register("type", {
                    required: "A score type is required",
                  })}
                  value={t.name}
                />
                <div
                  className="peer-checked:ring-4 peer-checked:ring-brown
                  bg-white rounded-xl shadow shadow-white p-4 flex flex-col items-center max-w-36 sm:max-w-40 cursor-pointer"
                >
                  <div className="h-14 w-14 bg-brown rounded-full p-2 flex justify-center items-center">
                    <img src={t.icon} className="h-8" />
                  </div>
                  <h3 className="font-medium">{t.name}</h3>
                  <p className="text-xs text-center">{t.desc}</p>
                </div>
              </label>
            ))}
            <div className="bg-white/60 rounded-xl shadow shadow-white p-4 flex flex-col items-center max-w-36 sm:max-w-40">
              <div className="h-14 w-14 bg-brown rounded-full p-2 flex justify-center items-center">
                <img src="/images/scorecard_icon.svg" className="h-8" />
              </div>
              <h3 className="font-medium">Scorecard</h3>
              <p className="text-xs text-center">
                Coming soon! Perfect for board games with scoresheets.
              </p>
            </div>
          </div>
          <FormError error={errors.type} />
          <Button>Start Game</Button>
        </form>
      </div>
    </>
  );
}
