import { ActionFunctionArgs, redirect } from "react-router-dom";

export type UserRes = {
  id: string;
  name: string;
  email?: string;
  avatarUrl: string;
};

export async function getDefaultAvatars(): Promise<string[]> {
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/avatars");
  return await response.json();
}

// Check if the profile has been completed
// If not redirect to profile edit
export async function profileCheckLoader() {
  const profile = await getProfile();
  if (!profile) return redirect("/profile/create");
  return profile;
}

export async function getProfile(): Promise<UserRes | null> {
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/profile");
  if (response.ok) return await response.json();
  if (response.status == 404) {
    return null;
  }
  throw Error("Error loading profile");
}

export async function createProfile({
  request,
}: ActionFunctionArgs): Promise<Response> {
  let formData = await request.formData();
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/profile", {
    method: "POST",
    body: formData,
  });
  let err = "Something went wrong!";
  if (response.ok) return redirect("/games/create");
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function editProfile({
  request,
}: ActionFunctionArgs): Promise<Response> {
  let formData = await request.formData();
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/profile", {
    method: "PUT",
    body: formData,
  });
  let err = "Something went wrong!";
  if (response.ok) return redirect("/profile");
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function getFriends(): Promise<UserRes[]> {
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/friends");
  return await response.json();
}
export async function getFriendRequests(): Promise<UserRes[]> {
  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + "/friends/requests",
  );
  return await response.json();
}

export async function createFriend({
  request,
}: ActionFunctionArgs): Promise<Response> {
  let formData = await request.formData();
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/friends", {
    method: "POST",
    body: formData,
  });
  let err = "Something went wrong!";
  if (response.ok) return response;
  else if (response.status == 400) err = (await response.json()).message;

  throw Error(err);
}

export async function addFriend(email: string): Promise<string | null> {
  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + "/friends/add",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    },
  );
  if (response.ok) return null;
  else if (response.status == 400) return "Invalid email.";
  else if (response.status == 404)
    return "User does not exist. Please create a local account and invite.";

  throw response;
}

export async function fulfilFriend(
  id: string,
  email: string,
): Promise<string | null> {
  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + "/friends/fulfil",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id, email: email }),
    },
  );
  if (response.ok) return null;
  else if (response.status == 400) return "Invalid email.";
  else if (response.status == 409)
    return "User already exists. Merging accounts is not currently supported, sorry for any inconvenience.";

  throw response;
}
