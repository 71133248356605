type Props = {
  error: any;
};
export default function FormError({ error }: Props) {
  return (
    <>
      {error && <p className="mb-2 font-main text-red-600">{error.message}</p>}
    </>
  );
}
