import { ProfileForm } from "@/routes/profile/form";
import NavBar from "@/components/ui/nav_bar";
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

export default function GroupAddFriend(props: { prevStep: () => void }) {
  let location = useLocation();

  return (
    <>
      <NavBar logo>
        <Button variant="icon" size="icon" onClick={() => props.prevStep()}>
          <FontAwesomeIcon icon={faChevronLeft} className="text-3xl" />
        </Button>
      </NavBar>

      <ProfileForm
        action={location.pathname}
        callback={props.prevStep}
        btnTxt="Add"
      />
    </>
  );
}
