import { GameStateT } from "./routes/games/create/type";

export class GameStore {
  static getKey(id: string): string {
    return `game_${id}`;
  }

  public static set(id: string, state: GameStateT) {
    localStorage.setItem(GameStore.getKey(id), JSON.stringify(state));
  }

  public static get(id: string): GameStateT | undefined {
    let state = localStorage.getItem(GameStore.getKey(id));
    if (state) return JSON.parse(state);
  }
}
