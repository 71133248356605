import NavBar from "@/components/ui/nav_bar";
import { useEffect, useRef } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

function ProgressLoader() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.animate([{ width: "0%" }, { width: "100%" }], {
      duration: 3000,
      easing: "ease-out",
      fill: "forwards",
    });
  }, []);

  return (
    <div
      ref={ref}
      className="fixed z-50 top-0 left-0 h-1.5 bg-brown shadow shadow-brown"
    ></div>
  );
}

export function Root() {
  const navigation = useNavigation();
  return (
    <SessionAuth>
      {["submitting", "loading"].includes(navigation.state) && (
        <ProgressLoader />
      )}
      <Outlet />
    </SessionAuth>
  );
}

export function Login() {
  return (
    <>
      <NavBar logo />

      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-medium">Pocket Tally</h2>
        <p className="italic text-sm font-medium font-main">
          Count every win...
        </p>
        <Outlet />
      </div>
    </>
  );
}
