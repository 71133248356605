import { deleteGroup } from "@/api/game";
import { TallyGroupRes } from "@/api/tally";
import { AvatarTeamSm } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import NavBar from "@/components/ui/nav_bar";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { permissions } from "@/permissions";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  useNavigate,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";

export function TallyList() {
  const navSubmit = useSubmit();
  const navigate = useNavigate();
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;

  const teams = loaderData.tallies[0].scores.map((s) => s.team);
  const scores = teams.map((team) =>
    loaderData.tallies.map(
      (tally) => tally.scores.find((s) => s.team.id == team.id)?.score || 0,
    ),
  );

  async function newScore() {
    navSubmit(null, {
      method: "post",
      action: `/games/${gameID}/groups/${groupID}/trackers`,
    });
  }
  async function deleteG() {
    await deleteGroup(groupID || "");
    navigate("/home");
  }

  return (
    <>
      <NavBar logo home>
        <div />
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Who's Winning?</h2>

        <Card>
          <CardHeader>
            <CardTitle>{loaderData.game_name}</CardTitle>
            <div className="flex gap-1">
              {loaderData.permission_level >= permissions.Admin && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="iconAlt" size="iconAlt">
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you absolutely sure?</DialogTitle>
                      <DialogDescription>
                        This action cannot be undone. This will permanently
                        delete this group and the scores within.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <Button variant="destructive" size="sm" onClick={deleteG}>
                        Confirm
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </CardHeader>
          <CardContent className="px-0 py-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead></TableHead>
                  {teams.map((t) => (
                    <TableHead key={t.id}>
                      <AvatarTeamSm users={t.users} />
                    </TableHead>
                  ))}
                  {loaderData.permission_level >= permissions.Manager && (
                    <TableHead></TableHead>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {loaderData.tallies.map((tally, i) => (
                  <TableRow key={tally.id}>
                    <TableCell>
                      {new Date(tally.created_at).toLocaleDateString(
                        undefined,
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        },
                      )}
                    </TableCell>
                    {teams.map((_, j) => (
                      <TableCell key={j}>{scores[j][i]}</TableCell>
                    ))}
                    {loaderData.permission_level >= permissions.Manager && (
                      <TableCell className="p-0">
                        <Link
                          to={`/games/${gameID}/groups/${groupID}/trackers/${tally.id}`}
                          className="px-2 py-4"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Totals</TableCell>
                  {teams.map((t, i) => (
                    <TableCell key={t.id}>
                      {scores[i].reduce((sum, current) => sum + current)}
                    </TableCell>
                  ))}
                  {loaderData.permission_level >= permissions.Manager && (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>

        {loaderData.permission_level >= permissions.Manager && (
          <div className="flex justify-center">
            <Button onClick={newScore}>New Score</Button>
          </div>
        )}
      </div>
    </>
  );
}
