import { ActionFunctionArgs } from "react-router-dom";
import { TeamRes } from "./game";

type TallyUpdate = {
  scores: { team: string; score: number };
};

export type TallyScoreRes = {
  team: TeamRes;
  score: number;
};
export type TallyRes = {
  id: string;
  created_at: string;
  updated_at: string;
  scores: TallyScoreRes[];
};
export type TallyGroupRes = {
  group_id: string;
  game_name: string;
  permission_level: number;
  tallies: TallyRes[];
};

export async function getTally({
  params,
}: ActionFunctionArgs): Promise<TallyGroupRes | undefined> {
  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + `/groups/${params.groupID}/tallies`,
  );
  if (response.ok) return await response.json();
}

export async function createTally({
  params,
}: ActionFunctionArgs): Promise<Response> {
  let groupID = params.groupID || "";

  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + `/groups/${groupID}/tallies`,
    { method: "POST" },
  );
  return response;
}

export async function updateTally({
  request,
  params,
}: ActionFunctionArgs): Promise<Response> {
  let groupID = params.groupID || "";
  let tallyID = params.trackerID || "";

  let payload = (await request.json()) as TallyUpdate;

  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + `/groups/${groupID}/tallies/${tallyID}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    },
  );
  return response;
}
