import { ReactNode, useState } from "react";
import { FriendSelect } from "./user_select";
import { UserRes } from "@/api/user";
import { createGroup } from "@/api/game";
import { useParams, useRouteLoaderData, useSubmit } from "react-router-dom";
import GroupAddFriend from "./add_friend";
import { TeamSelect } from "./team_select";

export function GroupCreate() {
  const navSubmit = useSubmit();
  const params = useParams();
  const gameID = params.gameID || "";

  // State required to create the game
  const userData = useRouteLoaderData("profilecheck") as UserRes;
  const friendState = useState<UserRes[]>([userData]);
  const teamsState = useState<UserRes[][]>([[]]);

  // Creates the teams and game with the backend then redirects
  async function submit(useTeams: boolean) {
    // Create teams
    let teams;
    if (!useTeams) {
      teams = friendState[0].map((x) => [x.id]);
    } else {
      // Exclude empty teams
      teams = teamsState[0]
        .filter((t) => t.length != 0)
        .map((t) => t.map((u) => u.id));
    }

    // Create group
    // TODO; send to dispatch action that:
    // - Creates group
    // - Checks game type (localStorage dep + url update)
    // - POSTs to correct tracker create action
    const groupID = await createGroup(gameID, teams);

    navSubmit(null, {
      method: "post",
      action: `/games/${gameID}/groups/${groupID}/trackers`,
    });
  }

  // Wizard for navigation
  const [wizard, wizardSetter] = useState(["friends"]);
  function nextStep(step: string) {
    // TODO; validate step is in steps

    if (step == "submit_friends") submit(false);
    if (step == "submit") submit(true);
    else wizardSetter([...wizard, step]);
  }
  function prevStep() {
    let w = [...wizard];
    w.pop();
    wizardSetter(w);
  }

  // Every page knows it's own forward route(s)
  // Backwards navigation handled by the wizard
  const steps: { [id: string]: ReactNode } = {
    friends: <FriendSelect nextStep={nextStep} friendState={friendState} />,
    addFriend: <GroupAddFriend prevStep={prevStep} />,
    teams: (
      <TeamSelect
        prevStep={prevStep}
        nextStep={nextStep}
        friendState={friendState}
        teamsState={teamsState}
      />
    ),
  };

  return <>{steps[wizard[wizard.length - 1]]}</>;
}
