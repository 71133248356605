import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";

const outerVariants = cva("font-semibold", {
  variants: {
    size: {
      default: "text-md",
      lg: "text-6xl",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const upVariants = cva("relative text-orangeDark", {
  variants: {
    size: {
      default: "bottom-0.5",
      lg: "bottom-1.5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});
const downVariants = cva("relative top-1.5 text-brown", {
  variants: {
    size: {
      default: "top-0.5",
      lg: "top-1.5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

interface Props extends VariantProps<typeof outerVariants> {
  className?: string;
}

export function Versus(props: Props) {
  return (
    <span
      className={cn(
        outerVariants({
          size: props.size,
          className: props.className,
        }),
      )}
    >
      <span className={cn(upVariants({ size: props.size }))}>v</span>
      <span className={cn(downVariants({ size: props.size }))}>s</span>
    </span>
  );
}

export function DoubleQ(props: Props) {
  return (
    <span
      className={cn(
        outerVariants({
          size: props.size,
          className: props.className,
        }),
      )}
    >
      <span className={cn(upVariants({ size: props.size }))}>?</span>
      <span className={cn(downVariants({ size: props.size }))}>?</span>
    </span>
  );
}
