import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: [import.meta.env.VITE_API_DOMAIN],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });
}
