import { ProfileForm } from "@/routes/profile/form";
import NavBar from "@/components/ui/nav_bar";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { createFriend } from "@/api/user";

export async function friendsCreateAction(
  args: ActionFunctionArgs,
): Promise<Response> {
  await createFriend(args);
  return redirect("/friends");
}

export function FriendsCreate() {
  return (
    <>
      <NavBar back="/friends" logo />
      <ProfileForm action="/friends/create" btnTxt="Continue" />
    </>
  );
}
