import { useRouteLoaderData } from "react-router-dom";
import { UserRes } from "@/api/user";
import { ProfileForm } from "./form";
import NavBar from "@/components/ui/nav_bar";

export default function ProfileEdit() {
  const loaderData = useRouteLoaderData("profilecheck") as UserRes;

  return (
    <>
      <NavBar back="/profile" logo />

      <ProfileForm
        action="/profile/edit"
        btnTxt="Save"
        initialData={loaderData}
      />
    </>
  );
}
