import { GameStore } from "@/storage";
import { UserRes } from "./user";
import { ActionFunctionArgs, redirect } from "react-router-dom";

export enum GameType {
  Tally = "Tally",
  Scorecard = "Scorecard",
  Tournament = "Tournament",
}
export type TeamRes = {
  id: string;
  users: UserRes[];
};
export type GroupRes = {
  id: string;
  teams: TeamRes[];
};
export type GameRes = {
  id: string;
  name: string;
  type: string;
  permission_level: number;
  groups: GroupRes[];
};

export async function createGame({
  request,
}: ActionFunctionArgs): Promise<Response> {
  let state = await request.json();
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/games", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(state),
  });
  if (response.ok) {
    let id = (await response.json()).id;
    GameStore.set(id, state);
    return redirect(`/games/${id}/groups/create`);
  }
  throw Error("Error creating game.");
}

export async function createGroup(
  gameID: string,
  teams: string[][],
): Promise<string> {
  const teamsReq = teams.map((l) => ({ ids: l }));
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + "/groups", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ game: gameID, teams: teamsReq }),
  });
  if (response.ok) return (await response.json()).id;
  else throw Error("Error creating group.");
}

export async function deleteGroup(group: string) {
  const response = await fetch(
    import.meta.env.VITE_API_DOMAIN + `/groups/${group}`,
    {
      method: "DELETE",
    },
  );
  if (response.ok) return;
  else throw response;
}

export async function getGames(): Promise<GameRes[]> {
  const response = await fetch(import.meta.env.VITE_API_DOMAIN + `/games`);
  if (response.ok) return await response.json();
  else throw Error("Error loading games.");
}
