import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faGear,
  faHouse,
  faPlus,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@/components/ui/button";
import { GameRes, GroupRes } from "@/api/game";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AvatarTeamSm } from "@/components/ui/avatar";
import { permissions } from "@/permissions";
import NavBar from "@/components/ui/nav_bar";
import { Separator } from "@/components/ui/separator";
import { Versus } from "@/components/ui/double_letters";
import { Fragment } from "react/jsx-runtime";
import { HotjarIdentify } from "@/hotjar";
import { useEffect } from "react";
import { GameStore } from "@/storage";

export default function Home() {
  const loaderData = useLoaderData() as GameRes[];
  const navigate = useNavigate();

  HotjarIdentify();

  useEffect(() => {
    async function f() {
      for (let g of loaderData) {
        GameStore.set(g.id, { name: g.name, type: g.type });
      }
    }
    f();
  }, []);

  function showGroups(gameID: string, groups: GroupRes[]) {
    return (
      <>
        {groups.map((g, i) => (
          <div key={g.id}>
            {i != 0 && <Separator />}
            <div
              className="flex items-stretch justify-between py-1.5 gap-2"
              onClick={() =>
                navigate(`/games/${gameID}/groups/${g.id}/trackers`)
              }
            >
              <div className="flex flex-wrap items-center gap-1">
                {g.teams.map((t, j) => (
                  <Fragment key={t.id}>
                    {j != 0 && <Versus />}
                    <AvatarTeamSm users={t.users} />
                  </Fragment>
                ))}
              </div>
              <div className="flex items-center">
                <Separator orientation="vertical" className="h-4/6" />
                <Button variant="ghost" size="sm" className="m-0">
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <NavBar>
        <div className="flex">
          <img
            src="/pocket_tally_logo.png"
            alt="pocket tally logo"
            className="w-8 h-8 self-end"
          />
          <p className="p-2 self-center font-semibold">PocketTally</p>
        </div>
        <div>
          <Button variant="icon" size="icon" asChild>
            <Link to="/home">
              <FontAwesomeIcon
                icon={faHouse}
                className="text-[hsl(22,60%,50%)]"
              />
            </Link>
          </Button>
          <Button variant="icon" size="icon" asChild>
            <Link to="/friends">
              <FontAwesomeIcon icon={faUserGroup} />
            </Link>
          </Button>
          <Button variant="icon" size="icon" asChild>
            <Link to="/profile">
              <FontAwesomeIcon icon={faGear} />
            </Link>
          </Button>
        </div>
      </NavBar>

      <div className="mt-10 flex flex-col gap-6">
        <h2 className="text-xl font-medium">Games</h2>

        {loaderData.map((game) => (
          <Card key={game.id}>
            <CardHeader>
              <CardTitle className="text-xl">
                {game.name}
                {/*<CardDescription>{game.type}</CardDescription>*/}
              </CardTitle>

              {game.permission_level >= permissions.Manager && (
                <Button
                  variant="constructive"
                  size="xs"
                  onClick={() => navigate(`/games/${game.id}/groups/create`)}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" /> New Teams
                </Button>
              )}
            </CardHeader>
            <CardContent>{showGroups(game.id, game.groups)}</CardContent>
          </Card>
        ))}

        <div id="hotjarfeedback" />
        <div className="flex justify-center">
          <Button size="lg" asChild>
            <Link to="/games/create">New Game</Link>
          </Button>
        </div>
      </div>
    </>
  );
}
