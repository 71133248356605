import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, Fragment, SetStateAction, useEffect, useRef } from "react";
import {
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import { UserRes } from "@/api/user";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { useForm } from "react-hook-form";
import FormError from "@/components/ui/form_error";
import NavBar from "@/components/ui/nav_bar";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Checkbox } from "@/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";

type Inputs = {
  selectedFriends: UserRes[];
};

type FriendProps = {
  nextStep: (step: string) => void;
  friendState: [UserRes[], Dispatch<SetStateAction<UserRes[]>>];
};
export function FriendSelect(props: FriendProps) {
  const userData = useRouteLoaderData("profilecheck") as UserRes;
  const friendData = useLoaderData() as UserRes[];
  const loaderData = [userData, ...friendData];

  const [selectedFriends, selectedFriendsSetter] = props.friendState;

  // Add friend created via action to selected list
  const actionResult = useActionData() as { id: string };
  if (actionResult) {
    let newFriend = friendData.find((x) => x.id == actionResult.id);
    if (newFriend && !selectedFriends.find((x) => x.id == newFriend.id)) {
      selectedFriendsSetter([...selectedFriends, newFriend]);
    }
  }

  const {
    register,
    trigger,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) return;

    setValue("selectedFriends", selectedFriends);
    trigger("selectedFriends");
  }, [selectedFriends]);

  function userRow(u: UserRes) {
    return (
      <div className="flex items-center gap-2">
        <Avatar>
          <AvatarImage src={u.avatarUrl} />
        </Avatar>
        <p className="font-main font-medium leading-none">{u.name}</p>
      </div>
    );
  }

  function onCheck(user: UserRes, checked: CheckedState) {
    if (checked) {
      selectedFriendsSetter([...selectedFriends, user]);
    } else {
      selectedFriendsSetter(selectedFriends.filter((x) => x.id != user.id));
    }
  }

  return (
    <>
      <NavBar logo home>
        <div />
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Who's playing?</h2>

        <Drawer>
          <Card>
            <CardHeader>
              <CardTitle>Players</CardTitle>
              <DrawerTrigger asChild>
                <Button variant="constructive" size="xs">
                  <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add
                </Button>
              </DrawerTrigger>
            </CardHeader>
            <CardContent className="mt-2">
              <input
                className="hidden"
                {...register("selectedFriends", {
                  validate: () =>
                    selectedFriends.length != 0 || "Add some friends.",
                })}
              />
              <FormError error={errors.selectedFriends} />
              <div className="flex flex-col gap-1">
                {selectedFriends.map((u, i) => (
                  <Fragment key={u.id}>
                    {i != 0 && <Separator />}
                    {userRow(u)}
                  </Fragment>
                ))}
              </div>
            </CardContent>
          </Card>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Pick</DrawerTitle>
            </DrawerHeader>
            <div className="flex flex-col gap-1 max-h-96 overflow-y-scroll">
              {loaderData.map((u, i) => (
                <Fragment key={u.id}>
                  {i != 0 && <Separator />}
                  <label className="mx-1">
                    <div className="flex justify-between items-center">
                      {userRow(u)}
                      <Checkbox
                        defaultChecked={selectedFriends
                          .map((x) => x.id)
                          .includes(u.id)}
                        onCheckedChange={(b) => onCheck(u, b)}
                      />
                    </div>
                  </label>
                </Fragment>
              ))}
            </div>
            <DrawerFooter>
              <div className="flex justify-center">
                <Button
                  variant="outline"
                  size="sm"
                  className="w-28"
                  onClick={() => props.nextStep("addFriend")}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Create
                </Button>
                <DrawerClose asChild>
                  <Button variant="constructive" size="sm" className="w-28">
                    Add
                  </Button>
                </DrawerClose>
              </div>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <div className="flex flex-col items-center">
          <Button
            onClick={() => {
              firstUpdate.current = false;
              handleSubmit(() => props.nextStep("submit_friends"))();
            }}
          >
            Start Game
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              firstUpdate.current = false;
              handleSubmit(() => props.nextStep("teams"))();
            }}
          >
            Put into teams
          </Button>
        </div>
      </div>
    </>
  );
}
